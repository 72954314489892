// import axios from 'axios';
import { manifestationApi } from '@/config/axios/index';

export default {
  getCategories() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/category`,
    });
  },

  getSingleCategorySubjects(category) {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/category/${category}/subject`,
    });
  },

  getSubjectData(data) {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/subject/${data}`,
    });
  },

  getMostAccessedData() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/subject/mostaccessed`,
    });
  },

  getSearchData(){
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/search/category/subject`,
    });
  },

  getVotes(subject){
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/vote/${subject}`,
    });
  },

  postVote(voteInfo){
    return manifestationApi({
      method: 'POST',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/vote`,
      data: voteInfo
    });
  },

  fetchSvg(svgUrl) {
    return manifestationApi({
      method: 'GET',
      url: svgUrl,
    });
  },

  fetchHighlights() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/subject/highlight`,
    });
  },

  fetchReports() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/externalReport`,
    });
  },

  getTickets() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket`,
    });
  },

  getTicket(ticketId) {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket/${ticketId}`,
    });
  },

  getWppTicket(wppCode) {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket/whatsapp/${wppCode}`
    });
  },

  createTicket( ticket ) {
    return manifestationApi({
      method: 'POST',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket`,
      data: ticket,
    });
  },

  cancelTicket(ticketId, reason) {
    return manifestationApi({
      method: 'POST',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket/${ticketId}/cancel`,
      data: reason,
    });
  },

  getReason() {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/reason`,
    });
  },

  addAnswer(ticketId, answer) {
    return manifestationApi({
      method: 'POST',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket/${ticketId}/issuerAnswer`,
      data: answer,
    });
  },

  anonymousTicket( ticketCode ) {
    return manifestationApi({
      method: 'GET',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/ticket/protocol/${ticketCode}`
    });
  },

  evaluateTicket( evaluationInfo ) {
    return manifestationApi({
      method: 'POST',
      url: `${process.env.VUE_APP_MANIFESTATION_API_ROUTE}/evaluationSystem`,
      data: evaluationInfo,
    });
  }
};
