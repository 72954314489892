import { api } from '@/config/axios/index';

export default {
  getNews() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE_UPCITIES}/noticia`,
        params: {
          category: process.env.VUE_APP_PROCON_CATEGORY_ID,
          order: 'desc',
          sort: 'created_at'
        }
    });
  },

  getSingleNews(newsId) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE_UPCITIES}/noticia/${newsId}`
    });
  },
};
