module.exports = {
  CONTENT: {
    SUBHEADER: {
      city: 'CARIACICA',
    },
    APP: {
      name: 'Cariacica Digital'
    },
    HEADER: {
      title: 'Bem vindo a sua Unidade de Saúde Online',
      titleLogged: 'Olá, ',
      subtitle: 'Agende os serviços disponíveis em uma Unidade de Saúde da Prefeitura de Cariacica. Siga o passo a passo e leia com atenção todas as informações.',
    },
    STEPPER: {
      ONE: {
        title: 'Categoria',
        description: 'Selecione a categoria na qual deseja marcar um serviço',
      },
      TWO: {
        title: 'Serviços',
        description: 'Selecione o serviço e os detalhes do atendimento',
      },
      THREE: {
        title: 'Insira os dados',
        description: 'Insira os dados necessários para prosseguir',
      },
      FOUR: {
        title: 'Dados do agendamento',
        description: 'Escolha a data e o horário desejados.',
      },
      FIVE: {
        title: 'Confirmação',
        description: 'Confira seus dados e confirme o seu atendimento',
      },
    },
    HOME: {
      BANNERAPP: {
        title: 'APLICATIVO DE CARIACICA ',
        city: 'prefeitura de Cariacica'
      },
      INFOCARDS: {
        FIRST: {
          title: 'LISTA DE UNIDADES',
          description: 'Encontre informações de localização e contato das Unidades de Saúde do seu município.',
          buttonLabel: 'SAIBA MAIS',
        },
        SECOND: {
          title: 'VALIDAÇÃO DE CADASTRO',
          description: 'Após o cadastro seus dados são validados pela UBS e seu acesso é liberado em algumas horas.',
          buttonLabel: 'SAIBA MAIS',
        },
        THIRD: {
          title: 'DÚVIDAS FREQUENTES',
          description: 'Confira as dúvidas mais frequentes e veja dicas e soluções que preparamos para te auxiliar.',
          buttonLabel: 'SAIBA MAIS',
        }
      },
      SERVICES: {
        atendimento: 'https://agendamento.cariacica.es.gov.br/agendamento/',
        chamado: 'https://centraldeservicos.cariacica.es.gov.br/categorias/',
        negociacao: '',
        reclamacao: '/reclamacao-online',
      },
      SURVEYBANNER: {
        title: 'Dê sua opinião!',
        subtitle: 'Sugestões de como podemos melhorar a experiência do site? Mande para a gente.',
      },
      OTHERS: {
        LINKS: {
          googlePlay: 'https://prml.me/appandroidcariacica',
          appleStore: 'https://prml.me/appioscariacica',
        }
      },
    },
    HIGHLIGHTS: {
      FIRST: {
        title: 'Código de defesa do consumidor',
        description: 'O Código de Defesa protege e ampara você como consumidor. Saiba mais sobre seus direitos.',
        href: '/codigo-defesa-consumidor',
      },
      SECOND: {
        title: 'Dúvidas sobre o sistema?',
        description: 'Veja as dúvidas mais frequentes sobre o Procon online.',
        href: '/faq',
      },
      THIRD: {
        title: 'Mantenha seu cadastro atualizado',
        description: 'Isso garante seu acesso aos serviços oferecidos pelo Procon.',
        href: `${process.env.VUE_APP_MINHACONTA_ROUTE}/profile/information`
      },
      APPOINTMENT: {
        title: 'Marcar agendamento',
        description: 'Obtenha atendimento pessoal no Procon para resolver questões sobre seus direitos',
        href: '/agendamento/servico'
      },
      CONSULT: {
        title: 'Meus agendamentos',
        description: 'Confira os detalhes dos seus agendamentos realizados',
        href: '/agendamento/consulta'
      },
      MANIFESTATION: {
        title: 'Abrir chamado',
        description: 'Faça uma denúncia sobre um fornecedor que violou seus direitos como consumidor',
        href: '/denuncia/servico'
      },
      MYMANIFESTATIONS: {
        title: 'Meus chamados',
        description: 'Veja o andamento dos seus chamados pelo número de protocolo ou login',
        href: '/denuncia/solicitacoes'
      },
    },
    FOOTER: {
      ABOUT: {
        first: 'Sobre o Procon',
        second: 'Dúvidas',
      },
      MENU: {
        first: 'Reclamação',
        second: 'Minhas reclamações',
        third: 'Dúvidas',
      },
      CONTACT: {
        title: 'Em caso de dúvidas:',
        tel: '27 3354-5504',
        telLabel: 'Munícipe',
        email: 'procon@cariacica.es.gov.br',
        emailLabel: 'Email geral',
        address: ''
      },
      ICONS: {
        LINKS: {
          facebook: 'https://www.facebook.com/PrefeituraCariacica',
          linkedin: 'https://www.linkedin.com/company/prefeitura-municipal-de-cariacica',
          instagram: 'https://www.instagram.com/prefeituracariacica',
          youtube: 'https://www.youtube.com/user/prefeituracariacica',
        }
      }
    },
    AUTOCOMPLETE: {
      LOCATION: {
        lat: '-20.2655',
        lng: '-40.4203',
      },
      addressError: 'São permitidos apenas endereços da cidade de Cariacica'
    },
    FAQ: {
      faqItems: [
        {
          id: 0,
          question: 'Como funciona o Procon online?',
          answer: 'O consumidor realiza todo o processo de forma 100% online, através do computador ou celular. É possível abrir e acompanhar reclamações, participar de audiências, realizar denúncias, agendamentos para consultas presenciais ou online com o órgão e acesso à informações e boas práticas de consumo.'
        },
        {
          id: 1,
          question: 'Como funciona o login único?',
          answer: 'O login único é uma forma de acesso simplicado a várias funcionalidades da plataforma da sua cidade. Nele você acessa vários serviços com um mesmo e-mail e senha previamente cadastrados. ',
          link: {
            text: 'login único',
            ref: 'https://minhaconta.uppersoft.cc/pt-br',
          }
        },
        {
          id: 2,
          question: 'Como faço para abrir uma reclamação?',
          answer: 'Antes de abrir uma reclamação no Procon é importante entrar em contato com o fornecedor para buscar a resolução do problema. Não tendo sucesso e sendo necessário a intervenção do Procon, você vai precisar ter o cadastro na conta única da sua cidade, ir na seção do site “abrir reclamação” portanto os documentos necessários para abertura.',
          link: {
            text: 'conta única',
            ref: 'https://minhaconta.uppersoft.cc/pt-br',
          }
        },
        {
          id: 3,
          question: 'Quais os documentos necessários para abrir uma reclamação?',
          answer: '&#8226; Documento de identificação oficial com foto\n&#8226; Comprovante de residência\n&#8226; CPF\n&#8226; Nota Fiscal\n&#8226; Contrato de prestação de serviço (se tiver)\n&#8226; Fatura (se for caso, por exemplo, de reclamação de cartão de crédito, água, energia elétrica, celular, telefone)\n&#8226; Ordem de Serviço (se se tratar de conserto)\n&#8226; Termo de garantia\n&#8226; Orçamento\n&#8226; Anexe imagens de mensagens de e-mail ou registros de qualquer contato que você teve com o empresa sobre a compra.'
        },
        {
          id: 4,
          question: 'O que é o pedido à empresa?',
          answer: 'Existem várias formas de acordo na resolução de uma reclamação. O consumidor pode indicar algo de sua preferência no momento da abertura da reclamação, sem garantias de que o pedido será atendido. O pedido precisa expressar claramente o objetivo do consumidor, seja um esclarecimento, a apresentação de um documento, o encerramento do contrato, ou o cumprimento do contrato com a entrega do produto ou realização do serviço.\n\nExemplo: um mês após a compra de um celular o mesmo explodiu ao carregar. O consumidor pode querer um novo aparelho, seu dinheiro de volta ou o valor em crédito na loja.'
        },
        {
          id: 5,
          question: 'Quem pode abrir uma reclamação no Procon Municipal?',
          answer: 'O Procon presta atendimento a todo o consumidor que tenha qualquer problema ou dúvidas relacionadas ao Direito do Consumidor. Portanto, toda pessoa ou empresa que adquira ou use um produto ou serviço de um fornecedor e se sinta lesado, e resida no município do Procon, pode abrir uma reclamação.'
        },
        {
          id: 6,
          question: 'Posso abrir uma reclamação em nome de terceiro?',
          answer: 'Somente titulares da conta podem abrir uma reclamação.'
        },
        {
          id: 7,
          question: 'Como ver o andamento da minha reclamação?',
          answer: 'No Procon online você acompanha todas as atualizações através da plataforma web ou aplicativo em ‘minhas reclamações’. A cada atualização você também é notificado via e-mail e notificação no seu celular.'
        },
        {
          id: 8,
          question: 'Como realizar uma denúncia?',
          answer: 'As denúncias são realizadas na plataforma de ouvidoria da sua cidade, você pode acessar através da plataforma Procon Online.'
        },
        {
          id: 9,
          question: 'Qual a diferença entre reclamação e denúncia?',
          answer: 'A reclamação é o ato pelo qual o consumidor leva ao conhecimento do Procon determinada conduta praticada por fornecedor que ameaça ou viola o seu direito como consumidor (direito próprio). Por exemplo, quando o consumidor efetua uma compra pela internet e a loja não entrega o produto.A reclamação é o ato pelo qual o consumidor leva ao conhecimento do Procon determinada conduta praticada por fornecedor que ameaça ou viola o seu direito como consumidor (direito próprio). Por exemplo, quando o consumidor efetua uma compra pela internet e a loja não entrega o produto.\n\nJá a denúncia é o ato pelo qual o consumidor leva ao conhecimento do Procon uma conduta praticada por fornecedor que ameaça ou viola o direito de vários consumidores. Por exemplo, quando uma loja não precifica os produtos.'
        },
        {
          id: 10,
          question: 'Como faço pedir orientações ao Procon?',
          answer: 'O Procon disponibiliza uma agenda para atendimento presencial ou online para realização de orientações individuais ao consumidor. Os agendamentos são realizados na plataforma de agenda da sua cidade, você pode acessar através da plataforma Procon Online ou clicando aqui.',
          link: {
            text: 'clicando aqui',
            ref: 'https://agendamento.cariacica.es.gov.br/',
          }
        }
      ],
    },
    BREADCRUMB: {
      RECLAMATION: {
        index: 'Abrir reclamação',
        onlineRelation: 'Reclamação Online',
        finish: 'Reclamação realizada',
      },
      MYCOMPLAINTS: {
        index: 'Minhas reclamações',
        onlineRelation: 'Reclamação Online',
        details: 'Detalhes da reclamação'
      },
      CONSUMER: {
        index: 'Código de defesa do consumidor',
        audio: 'Código de defesa em áudio'
      },
      RANKING: {
        index: 'Ranking das reclamações'
      },
      PRICE: {
        index: 'Pesquisa de preços',
        details: 'Detalhes da pesquisa'
      },
      MANIFESTATION: {
        title: 'Chamado',
        service: 'Serviço',
        create: 'Abrir solicitação',
        confirm: 'Confirmar solicitação',
        list: 'Minhas solicitações'
      },
      APPOINTMENT: {
        title: 'Agendamento',
      }
    },
    // BOT: {
    //   name: '',
    //   id: '',
    //   url: '',
    //   color: '',
    // },
    COMPLAINT_RANKING: {
      title: 'Históricos de reclamações',
      description: 'Acompanhe o historico de reclamações de todas as empresas registradas no procon Cariacica',
      icon: 'WomanHeadache',
      bgIcon: 'BannerRedirectBg',
      href: 'Home',
      bgColor: '#f8e7e7'
    },
    MESSAGE: {
      MANIFESTATION: {
        noTicketFirst: 'Você não possui nenhuma solicitação registrada.',
        noTicketSecond: 'Caso deseje abrir uma nova solicitação, por favor',
      }
    },
    FILE: {
      //Before changing maxsize check with backend the maximum accepted size!
      maxSize: '5',
      images: 'São permitidos uploads de arquivos com as seguintes extensões:  jpg, png ou jpeg',
      imagesAndPDF: 'São permitidos uploads de arquivos com as seguintes extensões:  jpg, png, jpeg ou pdf'
    }
  }
};
