import { api } from '@/config/axios/index';

export default {
  getComplaints() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint?order=desc&sort=code`
    });
  },

  getComplaint(complaintId) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}`
    });
  },

  postComplaint(data) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint`,
      data: data
    });
  },

  postUserData(data) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/user/personal-documents`,
      data
    });
  },

  getEstablishmentType() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/establishment-type`,
    });
  },

  getUserDocuments(userId) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/user/personal-documents`,
      data: userId,
    });
  },
};
