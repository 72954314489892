import { api } from '@/config/axios/index';

export default {
  getPriceSurveys() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/price-survey`
    });
  },

  getPriceSurvey(priceSurveyId) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/price-survey/${priceSurveyId}`
    });
  },
};