<template>
  <!-- eslint-disable  max-len -->
  <div id="app">
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>
<script>
// eslint-disable max-len
import { hotjarScript } from '@/mixins/hotjarScript';
const realmTheme = process.env.VUE_APP_THEME_REALM ?? process.env.VUE_APP_KEYCLOAK_REALM;

export default {
  name: 'App',

  mixins: [ hotjarScript ],
  data: () => ({
    //
  }),
  computed: {
    privacyPoliciesURL() {
      switch(realmTheme) {
        case 'Uppersoft':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmvv':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmcariacica':
          return 'https://upcities.app/politica-privacidade-cariacica';
        case 'pmci':
          return 'https://upcities.app/politica-privacidade-cachoeiro-itapemirim';
        case 'pmv':
          return 'https://upcities.app/politica-privacidade-vitoria';
        default :
        return '#';
      }
    },

    processRealm() {
      return realmTheme === 'Uppersoft' ? 'upcities' : realmTheme;
    },
  },

  async created() {
    await this.includeGoogleMapsKey();
  },

  mounted() {
    setTimeout(() => {
      let url = document.getElementById('policies-link');

      url === null ? '' :  url.href = this.privacyPoliciesURL;
    }, 2500);

    if(process.env.NODE_ENV === 'production') {
      this.includeAnalytics();
    }
  },

  methods: {
    includeGoogleMapsKey() {
      let Script = document.createElement('script');

      Script.type = 'text/javascript';

      Script.setAttribute('defer', 'defer');

      Script.setAttribute('src', `https://maps.google.com/maps/api/js?key=${process.env.VUE_APP_MAPS_KEY}&libraries=places`);
      document.body.appendChild(Script);
      Script.async = true;
    },
  },

  metaInfo() {
    return {
      titleTemplate: '%s | Procon',

      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Faça reclamações com muita praticidade.',
          },

          // OpenGraph
          {
            vmid: 'og:title',
            property: 'og:title',
            content: 'Procon',
          },
          {
            vmid: 'og:site_name',
            property: 'og:site_name',
            content: 'Procon',
          },
          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            vmid: 'og:url',
            property: 'og:url',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: 'Faça reclamações com muita praticidade.',
          },

          // Twitter
          {
            vmid: 'twitter:card',
            name: 'twitter:card',
            content: 'Faça reclamações com muita praticidade.',
          },
          {
            vmid: 'twitter:site',
            name: 'twitter:site',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: 'Agendamento',
          },
          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: 'Faça reclamações com muita praticidade.',
          },
           {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
          {
            vmid: 'twitter:image:src',
            name: 'twitter:image:src',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },

          {
            vmid: 'name',
            itemprop: 'name',
            content: 'Agendamento',
          },
          {
            vmid: 'description',
            itemprop: 'description',
            content: 'Faça reclamações com muita praticidade.',
          },
          {
            vmid: 'image',
            itemprop: 'image',
            content: require(`@/assets/theme/${this.processRealm}/icons/og/meta.jpg`),
          },
        ],

        link: [
          {
            vmid: 'api',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_API_ROUTE}`,
          },
          {
            vmid: 'conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_KEYCLOAK_URL}`,
          },
          {
            vmid: 'minha-conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_MINHACONTA_ROUTE}`,
          },
          {
            vmid: 'canonical',
            rel: 'canonical',
            href: `${window.location.origin}`,
          },
          {
            vmid: 'favicon',
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: require(`@/assets/theme/${this.processRealm}/icons/favicon/favicon.png`),
          },
        ],
      };
  },
};
</script>

<style lang="scss">
  /**
   *
   * Project
   *
   */
  @import "@/assets/sass/project/_buttons.scss";
  @import "@/assets/sass/project/_form.scss";
  @import "@/assets/sass/project/_typography.scss";
  @import "@/assets/sass/project/_skeleton.scss";
  @import "@/assets/sass/project/_radio.scss";

  html {
    scroll-behavior: smooth;

    body {
      font-family: $font-sans;
      color: $black;
      font-weight: $font-regular;
      font-size: $text-base;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $main-bg;
      overflow-x: hidden;

      //responsive svg
      svg {
        max-width: 100%;
        height: auto;
      }

      #app {
        min-height: 100vh;
        font-family: $font-sans;

        // Changing modal background
        .v--modal-overlay {
          background-color: $blue-500;
        }

        .loading-page {
          background-color: rgba(darken(saturate($primary, 100%), 15%), 0.75);
          background-blend-mode: luminosity;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center right;
          min-height: 100vh;
          min-width: 100vw;
          display: flex;
          justify-content: center;
          padding: 7.8125rem 7.1875rem;//125px 115px

          @include screen(phone-only) {
            padding: 2rem;
          }

          .message {
            height: 100%;
            width: auto;
          }

          svg {
            color: $white;
            fill: $white;
          }
        }
      }

      &.blocked {
        overflow: hidden;
      }

      &.fixed {
        position: fixed;
      }
    }
  }

  .modal-override {
    border-radius: 0.5rem !important;
  }
</style>
