import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_ROUTE} `,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
});

const surveyApi = axios.create({
  baseURL: process.env.VUE_APP_SURVEY_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

const appointmentApi = axios.create({
  baseURL: process.env.VUE_APP_APPOINTMENT_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

const manifestationApi = axios.create({
  baseURL: process.env.VUE_APP_MANIFESTATION_API_ROUTE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

const setAuthHeader = (token) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  surveyApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  appointmentApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  manifestationApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const resetAuthHeader = () => {
  delete api.defaults.headers.common.Authorization;
  delete surveyApi.defaults.headers.common.Authorization;
  delete appointmentApi.defaults.headers.common.Authorization;
  delete manifestationApi.defaults.headers.common.Authorization;
};

export { api, surveyApi, appointmentApi, manifestationApi, setAuthHeader, resetAuthHeader };
export default api;
