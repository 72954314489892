import { api } from '@/config/axios/index';
import axios from 'axios';

export default {
  getShortcuts() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/short-cut`
    });
  },

  fetchSvg(svgUrl) {
    return axios.get(svgUrl, {
      responseType: 'text'
    });
  },
};
