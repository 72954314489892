import Vue from 'vue';
import '@/registerServiceWorker';
import '@/config/globalComponents/index'; // GlobalComponents
import '@/config/vueTheMask/index'; // Vue mask
import '@/config/vmodal/index'; // Modal
import '@/config/veeValidate/index'; // VeeValidate
import router from './router';
import store from './store';
import App from './App.vue';
import i18n from '@/config/i18n/index';
import tour from '@/config/tour/index';
import api from '@/config/axios/index';
import dayjs from '@/config/dayjs/index'; // DayJS
import { setAuthHeader } from '@/config/axios/index.js';
import VueGtag from 'vue-gtag';
import '@/config/sentry/index'; // Sentry
// Global SVG Component
Vue.config.productionTip = false;

// --- Prototype
Vue.prototype.$dayjs = dayjs;

let analyticsCITY = process.env.VUE_APP_ANALYTICS_GTAG_CITY ?
  { id: process.env.VUE_APP_ANALYTICS_GTAG_CITY } :
  {};

Vue.use(VueGtag, {
  includes: [analyticsCITY],
  config: { id: process.env.VUE_APP_ANALYTICS_GTAG }
});

new Vue({
  name: 'AppVue',
  router,
  store,
  i18n,
  tour,
  created() {
    let token = null;
    let refreshToken = null;
    let clientId = null;

    const urlParams = new URLSearchParams(window.location.search.substring(1));

    token = urlParams.get('token');
    refreshToken = urlParams.get('refresh_token');
    clientId = urlParams.get('client_id');

    if(token && refreshToken && clientId){
      // If the URL has token and user save the data on vuex
      this.$store.dispatch('setClientId', { clientId });
      this.$store.dispatch('userSetAutoLoginData', {
        origin: 'URL',
        token: token,
        refreshToken: refreshToken,
        clientId: clientId,
      });
    } else {
      if (store.getters.userIsLogged) {
        setAuthHeader(store.getters.userToken);
        this.$store.dispatch('initKeycloak', 'check-sso');
      }else{
        this.$store.dispatch('initKeycloak');
      }
    }

    this.$store.dispatch('clearModalToShow');

    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          /**
           * Checking if some request response has status code 401
           * if it's occurs then logout the user and reload page
          */
          if (error.response.status === 401 && (this.$route.name !== 'Home')) {
            // Reset any query string param
            await this.$router.replace({ name: 'Home', query: {} }).catch(() => {});
            this.$store.dispatch('userLogout');
          }
        }

        return Promise.reject(error);
      },
    );
  },
  render: (h) => h(App),
}).$mount('#app');
