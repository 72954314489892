import axios from 'axios';
import { appointmentApi } from '@/config/axios/index';

export default {
  getCategories() {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: '/api/v1/categoria'
    });
  },
  getServices(category) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `/api/v1/categoria/${category}/servico`
    });
  },
  listService(service) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}`
    });
  },
  listServicePlaces(service) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/unidades`
    });
  },
  datesWithAvailableSchedule(service) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/datas`
    });
  },
  datesWithAvailableScheduleOfPlace(service, place, appointmentType) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/unidade/${place}/datas?tipo=${appointmentType}`
    });
  },
  numberOfAvailableHoursOfAPlace(service, appointmentType) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/total-horarios?tipo=${appointmentType}`
    });
  },
  availableDateSchedule(service, date) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/horario?${date}`
    });
  },
  availableDateScheduleOfPlace(service, date, place, appointmentType) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/unidade/${place}/horario?dia=${date}&tipo=${appointmentType}&sort=data&order=asc`
    });
  },
  avaiableScheduleDetails(service, date, place) {
    return appointmentApi({
      method: 'GET',
      data: null,
      url: `api/v1/servico/${service}/unidade/${place}/horario/${date}`
    });
  },
  fetchSvg(svgUrl) {
    return axios.get(svgUrl, {
      responseType: 'text'
    });
  },
  postAppointment({ service, place, hour }, data) {
    return appointmentApi({
      method: 'POST',
      url: `api/v1/servico/${service.id}/unidade/${place.id}/horario/${hour.id}`,
      data: data,
    });
  },
  confirmAppointment(appointmentId) {
    return appointmentApi({
      method: 'POST',
      data: null,
      url: `/api/v1/agendamento/${appointmentId}/confirmar`,
    });
  }
};
