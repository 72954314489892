import { api } from '@/config/axios/index';

export default {
  postAgreement(complaintId) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/acceptAgreement`,
    });
  },

  postRefuseAgreement(complaintId, message) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/refuseAgreement`,
      data: message,
    });
  },

  postAgreementConfirmed(complaintId) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/finalizeByUser`,
    });
  },

  postAgreementUnfulfilled(complaintId, message) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/reportNonCompliance`,
      data: message,
    });
  },

  postAnswer(complaintId, data) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/answerContact`,
      data: data,
    });
  },

  postAnswerRefusedDocs(complaintId, data) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/complaint/${complaintId}/answer`,
      data: data,
    });
  },
};
