import dayjs from 'dayjs';
import { extend, setInteractionMode, configure } from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required, email, confirmed, min, max, regex, alpha_spaces, numeric, alpha_num
} from 'vee-validate/dist/rules';
import i18n from '@/config/i18n/index';
import { cpfValidator, susValidator, cpnjValidator } from '@/helpers';

configure({
  // eslint-disable-next-line no-underscore-dangle
  defaultMessage: (field, values) => i18n.t(`validation.${values._rule_}`, values),
  classes: {
    valid: 'form-item__input--valid',
    invalid: 'form-item__input--invalid',
  },
});

// Interaction
setInteractionMode('eager');

// Install rules
extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('numeric', numeric);
extend('min', min);
extend('max', max);
extend('regex', regex);
extend('alpha_spaces', alpha_spaces);
extend('alpha_num', alpha_num);
extend('birthdate', {
  params: ['lang'],
  validate(value, { lang }) {
    const language = lang.replace(/_/g, '-').toLowerCase();

    return dayjs(value, dayjs().locale(language).localeData().longDateFormat('L'), language, true).isValid()
      && dayjs(value, dayjs().locale(language).localeData().longDateFormat('L'), language, true).isBefore(dayjs());
  },
});
extend('cpf', {
  validate(value) {
    return cpfValidator(value) || susValidator(value);
  },
});
extend('cnpj', {
  validate(value) {
    return cpnjValidator(value);
  },
});
extend('cnpj_cpf', {
  validate(value) {
    return cpnjValidator(value) || cpfValidator(value);
  },
});
