module.exports = {
  VALIDATION: {
    cpf: 'Número de documento inválido',
    cnpj: 'Número de documento inválido',
    cnpj_cpf: 'Número de documento inválido',
    birthdate: 'Data de nascimento inválida',
  },
  DATATABLE: {
    COLUMN: {
      id: 'ID',
      name: 'NOME',
      code: 'Id. da reclamação',
      status: 'Situação da reclamação',
      created_at: 'Início',
      company_name: 'Empresa'
    }
  },
};
